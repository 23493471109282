<template>

  <div :class="cls" v-loading="loading">

    <el-select @change="changeAtId" v-model="atName" filterable>
      <template v-for="(item) in atList">
        <el-option :key="item.atId" :value="item.atName">{{item.atName}}</el-option>
      </template>
    </el-select>

    <!-- <el-cascader :props="props"></el-cascader> -->
  </div>
</template>
<script>
export default {
  name:"selectCity",
  data(){
    return{
      props: {
          lazy: true,
          lazyLoad (node, resolve) {
            const { level } = node;
            let abc=api.getCountrys();
            console.log(abc)
            const nodes=abc.map(item=>({
                  value: item.countryId,
                  label: item.countryName,
                  leaf: level >= 2
            }));

            
            resolve(nodes);
          //   setTimeout(() => {
          //     const nodes = Array.from({ length: level + 1 })
          //       .map(item => ({
          //         value: ++id,
          //         label: `选项${id}`,
          //         leaf: level >= 3
          //       }));

          //       console.log(nodes);
          //     // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          //     resolve(nodes);
          //   }, 1000);
           }
        },
      loading:false,
      
      //选中的权限
      atId:null,
      atName:null,
      //选中的权限 并网时间
      builddate:null,
      //权限列表
      atList:[],

    }
  },
  proct:['cls'],
  watch: {
    // atId(atId){
    //   alert(atId);
    //   this.$store.state.atId=atId;
    //   let tempBuilddate=(this.builddate.split(' '))[0];
    //   this.$emit("atIdChange", tempBuilddate,atId);
    // }
  },
  mounted(){
    
  },
  methods:{
    //电站切换
    changeAtId(){
      // let builddate=null;
     
      for(let i=0;i<this.atList.length;i++){
        if(this.atList[i]["atName"]==this.atName){
          this.buildDate=(this.atList[i]["atBuilddate"].split(' '))[0];
          this.atName=this.atList[i]["atName"];
          this.$store.state.atId=this.atList[i]["atId"];
          
          break;
        }
      }      
      // builddate=(builddate.split(' '))[0];
      // this.$emit("atIdChange", builddate,this.$store.state.atId);
      
      if(this.buildDate!=null){ 
        //let tempBuilddate=(this.buildDate.split(' '))[0];
        this.$emit("atIdChange", this.buildDate,this.$store.state.atId);
      }
      
      
    },

    
  }
}
</script>
