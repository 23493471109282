<template>
  <div id="main-content" class="main-content-normal" :class="{'main-content-bigger':$store.state.mainBig}">
    <div class="container-fluid">

      <div class="block-header">
        <div class="row clearfix">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <h1>
              <!-- 权限管理 -->
              {{ $t('authority.SectionName') }}
            </h1>
          </div>
        </div>
      </div>
      <div class="row clearfix mt-4">
        <div class="col-12">
          <div class="card">
            <div class="card-body">

              <el-tabs tab-position="left" v-model="activeName">
                <!-- 电站授权-->
                <el-tab-pane :label="$t('authority.StationAuthorize')" name="StationAuthorize">
                  <el-row :gutter="10">
                    <el-col :span="8">
                      <div class="table-responsive table-body-md StationAuthorize-List">
                        <el-table :data="AuthorityTableData" v-loading="loadingPage" hover :empty-text="$t('authority.noData')" highlight-current-row>
                          <!-- 请选择电站 -->
                          <el-table-column :label="$t('authority.ChooseStation')">
                            <!-- 电站名 -->
                            <el-table-column prop="StationId" :label="$t('authority.StList.StationName')">
                              <template slot="header">
                                <select-ps-id-view :cls="'col-12'" />
                              </template>
                              <template slot-scope="scopeid">
                                <i class="fal fa-home-alt"></i>
                                <span class="ml-1">{{ scopeid.row.StationId }}</span>
                              </template>
                            </el-table-column>
                            <!-- 序列号 -->
                            <el-table-column prop="SerialNumber" :label="$t('authority.StList.SerialNumber')" width="160" />
                          </el-table-column>
                        </el-table>
                        <el-pagination background layout="prev, pager, next" :total="150" align="center" class="mt-3" />
                      </div>
                    </el-col>
                    <el-col :span="16">
                      <div class="table-responsive table-body-md StationAuthorize-SonList">
                        <el-table :data="AuthoritySonTableData" v-loading="loadingPage" :empty-text="$t('authority.noData')" highlight-current-row>
                          <el-table-column :render-header="AuthoritySonTableTitle">
                            <el-table-column prop="UserId" :label="$t('authority.StSonList.UserId')" /> <!-- 用户ID -->
                            <el-table-column prop="UserName" :label="$t('authority.StSonList.UserName')" /> <!-- 姓名 -->
                            <el-table-column prop="UserCategory" :label="$t('authority.StSonList.UserCategory')" /> <!-- 类别 -->
                            <el-table-column prop="UserAuthority" :label="$t('authority.StSonList.UserAuthority')" /> <!-- 权限 -->
                            <el-table-column prop="Operate" :label="$t('authority.StSonList.Operate')">
                              <!-- 操作 -->
                              <template slot-scope="scope">
                                <el-button size="mini" icon="fad fa-pencil mr-1" @click="handleEdit(scope.$index, scope.row)" round plain>{{ $t('authority.StSonList.OperateBtn.Edit') }}
                                  <!-- 变更 -->
                                </el-button>
                                <el-button size="mini" type="danger" icon="fad fa-times mr-1" @click="handleDelete(scope.$index, scope.row)" round plain>{{ $t('authority.StSonList.OperateBtn.Delete') }}
                                  <!-- 删除 -->
                                </el-button>
                              </template>
                            </el-table-column>
                          </el-table-column>
                        </el-table>
                        <el-pagination background layout="prev, pager, next" :total="150" class="text-center mt-3" />
                      </div>
                    </el-col>
                  </el-row>

                </el-tab-pane>
                <el-tab-pane :label="$t('authority.StationTransfer')" name="StationTransfer">123</el-tab-pane>

              </el-tabs>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加电站授权弹框 -->
    <div id="AuthorityAddInfo" class="modal fade bd-Register-modal-lg" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title h4" id="myLargeModalLabel">
              <span class="fa-layers fa-fw fa-2x">
                <i class="fad fa-user-plus"></i>
              </span>
              <span>
                <!-- 添加权限 -->
                {{ $t('authority.AuthorityAdd') }}
              </span>
            </h5>
            <button type="button" class="close" data-dismiss="modal" @click="AuthorityAddInfoHide" aria-label="Close">
              <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
          </div>

          <div class="modal-body">
            <el-row :gutter="0">
              <el-col :span="24">
                <div class="form-group c_form_group">
                  <label>
                    <!-- 用户ID / Email -->
                    {{ $t("authority.UserIdEmail") }}
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="fad fa-user-circle text-dark-15"></i>
                      </span>
                    </div>
                    <input class="form-control" type="text" :placeholder="$t('authority.ExampleEmail')" />
                  </div>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="form-group c_form_group">
                  <label>
                    <!-- 姓名 -->
                    {{ $t("authority.UserName") }}
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="fad fa-file-signature text-dark-15"></i>
                      </span>
                    </div>
                    <input class="form-control" type="text" :placeholder="$t('authority.ExampleUserName')" />
                  </div>
                </div>
              </el-col>
              <el-col :span="24" align="center">
                <select-at-id-view />
              </el-col>
            </el-row>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal" @click="AuthorityEditInfoHide">
              <!-- 关闭 -->
              {{ $t("authority.StSonList.DialogBtn.Close") }}
            </button>
            <button type="button" class="btn btn-primary theme-bg gradient js-sweetalert" @click="confirmSave">
              <!-- 确认添加 -->
              {{ $t("authority.StSonList.DialogBtn.ConfirmAdd") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 变更电站授权弹框 -->
    <div id="AuthorityEditInfo" class="modal fade bd-Register-modal-lg" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title h4" id="myLargeModalLabel">
              <span class="fa-layers fa-fw fa-2x">
                <i class="fad fa-user-edit"></i>
              </span>
              <span>
                <!-- 变更权限 -->
                {{ $t('authority.AuthorityEdit') }}
              </span>
            </h5>
            <button type="button" class="close" data-dismiss="modal" @click="AuthorityEditInfoHide" aria-label="Close">
              <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
          </div>

          <div class="modal-body">
            <el-row :gutter="0">
              <el-col :span="24">
                <div class="form-group c_form_group">
                  <label>
                    <!-- 用户ID / Email -->
                    {{ $t("authority.UserIdEmail") }}
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="fad fa-user-circle text-dark-15"></i>
                      </span>
                    </div>
                    <input class="form-control" type="text" value="这里抓取原Email地址" />
                  </div>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="form-group c_form_group">
                  <label>
                    <!-- 姓名 -->
                    {{ $t("authority.UserName") }}
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="fad fa-file-signature text-dark-15"></i>
                      </span>
                    </div>
                    <input class="form-control" type="text" value="这里抓取原用户名" />
                  </div>
                </div>
              </el-col>
              <el-col :span="24" align="center">
                <select-at-id-view />
              </el-col>
            </el-row>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal" @click="AuthorityEditInfoHide">
              <!-- 关闭 -->
              {{ $t("authority.StSonList.DialogBtn.Close") }}
            </button>
            <button type="button" class="btn btn-primary theme-bg gradient js-sweetalert" @click="confirmSave">
              <!-- 确认变更 -->
              {{ $t("authority.StSonList.DialogBtn.ConfirmEdit") }}
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import SelectPsIdView from '../components/HistoryView/SelectPsId.vue'
import SelectAtIdView from '../components/AuthorityView/SelectAtId.vue'

export default {

  name: "Authority",
  components: {
    SelectPsIdView,
    SelectAtIdView,
  },

  data () {
    return {
      activeName: 'StationAuthorize',

      //权限管理数据表（静态测试数据）
      AuthorityTableData: [{
        StationId: '测试电站1',
        SerialNumber: 'EMS324310000000'
      }, {
        StationId: '测试电站2',
        SerialNumber: 'EMS324320000000'
      }, {
        StationId: '测试电站3',
        SerialNumber: 'EMS324330000000'
      }, {
        StationId: '测试电站4',
        SerialNumber: 'EMS324340000000'
      }, {
        StationId: '测试电站5',
        SerialNumber: 'EMS324350000000'
      }, {
        StationId: '测试电站6',
        SerialNumber: 'EMS324360000000'
      }, {
        StationId: '测试电站7',
        SerialNumber: 'EMS324370000000'
      }, {
        StationId: '测试电站8',
        SerialNumber: 'EMS324380000000'
      }, {
        StationId: '测试电站9',
        SerialNumber: 'EMS324390000000'
      }, {
        StationId: '测试电站10',
        SerialNumber: 'EMS324400000000'
      }, {
        StationId: '测试电站11',
        SerialNumber: 'EMS324410000000'
      }, {
        StationId: '测试电站12',
        SerialNumber: 'EMS324420000000'
      }, {
        StationId: '测试电站13',
        SerialNumber: 'EMS324430000000'
      }, {
        StationId: '测试电站14',
        SerialNumber: 'EMS324440000000'
      }, {
        StationId: '测试电站15',
        SerialNumber: 'EMS324450000000'
      }],

      //权限管理子数据表（静态测试数据）
      AuthoritySonTableData: [{
        UserId: '123456789',
        UserName: '小六子',
        UserCategory: '专业用户',
        UserAuthority: '管理',
      }, {
        UserId: '123456789',
        UserName: '小六子',
        UserCategory: '专业用户',
        UserAuthority: '管理',
      }, {
        UserId: '123456789',
        UserName: '小六子',
        UserCategory: '专业用户',
        UserAuthority: '管理',
      }, {
        UserId: '123456789',
        UserName: '小六子',
        UserCategory: '专业用户',
        UserAuthority: '管理',
      }, {
        UserId: '123456789',
        UserName: '小六子',
        UserCategory: '专业用户',
        UserAuthority: '管理',
      }, {
        UserId: '123456789',
        UserName: '小六子',
        UserCategory: '专业用户',
        UserAuthority: '管理',
      }, {
        UserId: '123456789',
        UserName: '小六子',
        UserCategory: '专业用户',
        UserAuthority: '管理',
      }, {
        UserId: '123456789',
        UserName: '小六子',
        UserCategory: '专业用户',
        UserAuthority: '管理',
      }, {
        UserId: '123456789',
        UserName: '小六子',
        UserCategory: '专业用户',
        UserAuthority: '管理',
      }, {
        UserId: '123456789',
        UserName: '小六子',
        UserCategory: '专业用户',
        UserAuthority: '管理',
      }, {
        UserId: '123456789',
        UserName: '小六子',
        UserCategory: '专业用户',
        UserAuthority: '管理',
      }, {
        UserId: '123456789',
        UserName: '小六子',
        UserCategory: '专业用户',
        UserAuthority: '管理',
      }, {
        UserId: '123456789',
        UserName: '小六子',
        UserCategory: '专业用户',
        UserAuthority: '管理',
      }, {
        UserId: '123456789',
        UserName: '小六子',
        UserCategory: '专业用户',
        UserAuthority: '管理',
      }, {
        UserId: '123456789',
        UserName: '小六子',
        UserCategory: '专业用户',
        UserAuthority: '管理',
      }]

    }
  },

  methods: {
    //表头参数名
    /* 电站授权子表格表头 */
    AuthoritySonTableTitle (h) {
      return (
        <div class="SonTableTitle row clearfix">
          <div class="text-left col-8" style="bottom:-6px"><h4><i class="fad fa-home-alt mr-2"></i>这里抓取左表格对应电站名</h4></div>
          <div class="text-right col-4"><el-button type="warning" icon="fad fa-plus-circle mr-1" onClick={() => this.handleAdd()}>{this.$t('authority.StSonList.OperateBtn.Add')}</el-button></div>
        </div>
      )
    },

    /* 触发更改操作弹框 */
    handleEdit () {
      $("#AuthorityEditInfo").modal("show");
    },
    AuthorityEditInfoHide () {
      $("#AuthorityEditInfo").modal("hide");
      $(".modal-backdrop").remove();
    },
    /* 触发添加操作弹框 */
    handleAdd () {
      $("#AuthorityAddInfo").modal("show");
    },
    AuthorityAddInfoHide () {
      $("#AuthorityAddInfo").modal("hide");
      $(".modal-backdrop").remove();
    },

    /* 弹框确认按钮 */
    confirmSave () {
      if (this) {
        //swal( this.$t('authority.StSonList.SwalEdit.SonTitleSuccess'), this.$t('authority.StSonList.SwalEdit.SonTextSuccess'), "success");
        swal({
          //提示的标题
          title: this.$t('authority.StSonList.SwalEdit.SonTitleSuccess'),
          //提示的文本
          text: this.$t('authority.StSonList.SwalEdit.SonTextSuccess'),
          //类型：警告，还有success,error和info
          type: "success",
          //显示取消按钮
          showCancelButton: false,
          //确认按钮内文本
          confirmButtonText: this.$t("login.msg.determine") //'确定'
        });
      } else {
        //swal( this.$t('authority.StSonList.SwalEdit.SonTitleErro'), this.$t('authority.StSonList.SwalEdit.SonTextErro'), 'error');
        swal({
          //提示的标题
          title: this.$t('authority.StSonList.SwalEdit.SonTitleErro'),
          //提示的文本
          text: this.$t('authority.StSonList.SwalEdit.SonTextErro'),
          //类型：警告，还有success,error和info
          type: "error",
          //显示取消按钮
          showCancelButton: false,
          //确认按钮内文本
          confirmButtonText: this.$t("login.msg.determine") //'确定'
        });
      }
    },

    /* 确认删除按钮 */
    handleDelete (index, row) {
      let _this = this;
      swal({
        //提示的标题 
        title: this.$t('authority.StSonList.SwalDelete.Title'),
        //提示的文本
        text: this.$t('authority.StSonList.SwalDelete.Text'),
        //类型：警告，还有success,error和info
        type: 'warning',
        //显示取消按钮
        showCancelButton: true,
        //确认按钮颜色
        confirmButtonColor: '#ff6347',
        //确认按钮内文本
        confirmButtonText: this.$t('authority.StSonList.SwalDelete.DialogBtn.ConfirmDelete'),
        //取消按钮内文本
        cancelButtonText: this.$t('authority.StSonList.SwalDelete.DialogBtn.ConfirmCancel'),
        closeOnConfirm: false,
        closeOnCancel: false,
      }, async function (isConfirm) {
        if (isConfirm) {
          if (index % 2 == 0) {
            swal(_this.$t('authority.StSonList.SwalDelete.SonTitleSuccess'), _this.$t('authority.StSonList.SwalDelete.SonTextSuccess'), 'success');
          }
          else {
            swal(_this.$t('authority.StSonList.SwalDelete.SonTitleErro'), _this.$t('authority.StSonList.SwalDelete.SonTextErro'), 'error');
          }

          // //点击确认按钮之后进行的操作，这里success不能改
          // //发送激活邮件
          //   const {data:res}=  await loginVue.$http.get('/service-user/user/sendActiveMail?userCode='+loginVue.loginForm.userCode);
          //   if(res.status==200){
          //       //'邮件发送成功','请到注册邮箱中查收'
          //     swal( loginVue.$t('login.msg.sendActiveEmail'),  loginVue.$t('login.msg.pleaseCheck'), 'success');
          //   }
          //   else{
          //     swal(loginVue.$t('login.code.'+res.status), loginVue.$t('login.msg.goBack'), "warning");
          //   }

        } else {
          //点击取消按钮之后进行的操作，这里error不能改
          //'取消'
          swal(_this.$t('authority.StSonList.SwalDelete.SonTitleCancel'), _this.$t('authority.StSonList.SwalDelete.SonTextCancel'), 'info');
        }
      })
      console.log(index, row);
    }
  },



}
</script>

<style>
.StationAuthorize-SonList .el-table__header th:nth-child(1),
.StationAuthorize-List .el-table__body tr td:nth-child(1),
.StationAuthorize-SonList .el-table__body tr td:nth-child(1) {
  text-align: left !important;
}
</style>
